export const BINANCE = {
	name: 'BNB Smart Chain',
	coinGeckoId: 'binance',
	ethersId: 'binance',
	graphUrl:
		'https://api.thegraph.com/subgraphs/name/streamable-finance/streampay-bsc',
	togaAddress: '',
	rpcUrl: 'https://bsc-dataseed1.binance.org/',
	getTxLink: (transactionId) =>
		`https://testnet.bscscan.com/tx/${transactionId}`,
};
export const CHAPEL = {
	name: 'BNB Smart Chain Testnet',
	coinGeckoId: 'binance',
	ethersId: 'chapel',
	graphUrl:
		'https://graph2.stream.superhow.net/subgraphs/name/streamable-finance/streampay',
	togaAddress: '',
	rpcUrl: 'https://winter-dry-bush.bsc-testnet.quiknode.pro/6144cbcc4ef8fbb7161a6aa42a4650a53c1d47ff/',
	getTxLink: (transactionId) => `https://bscscan.com/tx/${transactionId}`,
};
export const UNSUPPORTED = { name: 'Unsupported network' };

export const NETWORK_LIST = [BINANCE, CHAPEL];

export function getNetworkByEthersId(etherName) {
	console.log('etherName, ', etherName);
	return (
		NETWORK_LIST.find((n) => n.ethersId === etherName.toLowerCase()) ||
		UNSUPPORTED
	);
}
